.item {
    /* background-color: grey; */
    width: 98%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    height: 20vh;
    margin: 10px auto;
}
@media only screen and (max-width: 600px) { 
    
 .item{

     
     width: 90%;
}  
  }
.item-inner {
    font-size: medium;
    display: flex;
    /* background: red; */
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.item-price {
    height: 50%;
    padding: 5px;
    min-width: 100px;
    background-color: wheat;
    border-radius: 13px;
    border: 2px solid black;
    display: flex;
    flex-direction: row;
    color: black;
    justify-content: center;
    align-items: center;
}

.item-name {
    height: 50%;
    padding: 0px 10px;
    /*
    border-radius: 13px;
    border: 2px solid black; */
    font-size: medium;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid white;
}