.cont {
    width: 95%;
    /* background-color: white; */
    border-radius: 13px;
    /* border: 2px solid black; */
    background-color: rgb(31, 31, 31);
    width: 90%;
    height: 90%;
    border-radius: 12px;
    box-shadow: 0px 0px 3px white;
}

.hr {
    border-top: 2px solid white;
    padding: 40px;
}