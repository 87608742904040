.new-expense {
    background-color: #a892ee;
    padding: 1rem;
    margin: 2rem auto;
    width: 85vw;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.btn {
    background-color: antiquewhite;
    border: none;
    border: 1px solid white;
    border-radius: 10px;
    height: 45px;
    width: 105px;
    margin: 5px 0px;
}